<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-4">
          <h1 class="font-black text-main-purple-500 text-6xl text-center my-4">
            LGPD
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
          A Lei nº 13.709/18 – Lei Geral de Proteção de Dados (LGPD), em vigor desde 18 de setembro de 2020, inspirada na legislação europeia, General Data Protection Regulation (GDPR), foi criada para instituir regras e diretrizes gerais sobre o tratamento de dados pessoais no Brasil.
          </h3>
        </div>
        <div class="flex flex-col justify-center">
          <div class="w-full max-w-4xl mx-auto">
            <!-- Vertical Timeline #1 -->
            <div class="-my-6">
              <faq-accordion faq-question="O que é a LGPD?">
                <p>A Lei Geral de Proteção de Dados (13.709/2018) tem como principal objetivo proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural. Também tem como foco a criação de um cenário de segurança jurídica, com a padronização de regulamentos e práticas para promover a proteção aos dados pessoais de todo cidadão que esteja no Brasil, de acordo com os parâmetros internacionais existentes. </p>
              </faq-accordion>
              
              <!-- <faq-accordion faq-question="Como posso obter informações relativas aos dados pessoais coletados pela Nova?">
                <p>Em respeito a você, titular dos dados e em conformidade com a legislação, disponibilizamos um canal onde você poderá exercer seus direitos e buscar informações relativas aos dados coletados.</p>
              </faq-accordion> -->

              <faq-accordion faq-question="O que são dados pessoais na LGPD?">
                <p>•	De acordo com a Lei Geral de Proteção de Dados (LGPD), dados pessoais são informações que se referem a uma pessoa natural identificada ou identificável, como nome, Sobrenome, RG, CPF, endereço residencial etc.</p>
                <p class="mt-3">A LGPD protege os direitos de privacidade, liberdade e desenvolvimento das pessoas físicas, estabelecendo regras para o tratamento dos seus dados.</p>
              </faq-accordion>

              <faq-accordion faq-question="Como é feito o consentimento dos dados?">
                <p>A lei traz várias garantias ao cidadão, como: poder solicitar que os seus dados pessoais sejam excluídos; revogar o consentimento; transferir dados para outro fornecedor de serviços, entre outras ações.</p>
                  <p class="mt-3">Na LGPD, o consentimento do titular dos dados é considerado elemento essencial para o tratamento, regra excepcionada nos casos previstos no art. 11, II, da Lei.
                </p>
              </faq-accordion>
<!-- 
              <faq-accordion faq-question="O que é tratamento de dados pessoais?">
                <p>É qualquer operação ou o conjunto de operações realizadas com dados pessoais ou com um conjunto de dados pessoais, tais como a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>
              </faq-accordion>

              <faq-accordion faq-question="Quem é o titular de dados pessoais?">
                <p>Somos todos nós! Pessoas naturais a quem os dados dizem respeito.</p>
              </faq-accordion> -->

              <faq-accordion faq-question="Quem fiscaliza?">
                <p>Para fiscalizar e aplicar penalidades pelos descumprimentos da LGPD, o Brasil conta com a Autoridade Nacional de Proteção de Dados Pessoais, a ANPD. A instituição terá as tarefas de regular e de orientar, preventivamente, sobre como aplicar a lei. No entanto, não basta a ANPD (Lei nº 13.853/2019) e é por isso que a Lei Geral de Proteção de Dados Pessoais também prevê a existência dos agentes de tratamento de dados e estipula suas funções, nas organizações, como: o controlador, que toma as decisões sobre o tratamento; o operador, que realiza o tratamento, em nome do controlador; e o encarregado, que interage com os titulares dos dados pessoais e a autoridade nacional.</p>
              </faq-accordion>

              <faq-accordion faq-question="A Nova se classifica como Operadora ou Controladora de dados pessoais?">
                <p>A Nova pode se classificar tanto como Operadora, quanto como Controladora de dados pessoais, a depender do contexto. Por exemplo, a Nova pode ser Controladora quando trata dados pessoais dos seus colaboradores e clientes (dados necessários para firmar contratos e processar pagamentos). Por outro lado, pode ser Operadora para fornecer produtos e serviços, sendo que, para isso, precisa tratar dados referentes a essas transações e vínculos comerciais.</p>
              </faq-accordion>
<!-- 
              <faq-accordion faq-question="A Nova deverá responder sempre as solicitações relativas aos direitos dos titulares?">
                <p>A Nova deverá responder às solicitações dos titulares nas hipóteses em que ela como Controladora dos dados. Para as situações nas quais a Nova atue como Operadora, você deverá contatar o Controlador. Nós iremos responder informando como você deverá proceder e nesse caso, encaminharemos o seu pedido à empresa responsável por respondê-lo.</p>
              </faq-accordion> -->

              <faq-accordion faq-question="Como a Nova protege os dados pessoais?">
                <p>A Nova encontra-se em um momento de adequação à LGPD e está desenvolvendo medidas de segurança capazes de proteger os dados pessoais. São elas:</p>
                <ul>
                  <li class="mt-2">Coletar o consentimento dos dados dos clientes através dos canais de atendimentos da empresa e sendo de forma livre e informado e específico para cada finalidade. </li>
                  <li class="mt-2">Armazenar dados em redes privadas que são sensíveis as informações dos clientes.</li>
                  <li class="mt-2">Implementar criptografia na rede que transforma os dados de forma ilegível, ao menos que uma chave de decodificação seja aplicada.</li>
                  <li class="mt-2">Monitorar acessos aos dados sigilosos.</li>
                  <li class="mt-2">Treinar funcionários sobre a privacidade e as medidas que devem ser adotadas para a proteção dos dados pessoais.</li>
                  <li class="mt-2">Revisar as políticas internas de segurança.</li>
                  <li class="mt-2">Adotar normas internas que regulam a utilização de dados móveis dentro da dependência da empresa.</li>
                  <li class="mt-2">Comunicar os incidentes através de ferramentas oficiais, caso ocorra, à autoridade nacional e aos titulares de quais dados foram afetados.</li>
                </ul>
                <!-- <p class="mt-3">Você pode conferir mais detalhes sobre como a Nova protege seus dados acessando a nossa Política de Privacidade.</p> -->
              </faq-accordion>

              <faq-accordion faq-question="Quais documentos que a Nova demonstra que está se adequando a LGPD?">
                <p>A Nova trata a Privacidade e a Proteção de dados como Prioridade. Ressaltamos a nossa preocupação ao instituir internamente comissões de Privacidade e Proteção de Dados e Cibersegurança.</p>
                <p class="mt-3">Seguem os links abaixo que demonstra que a Nova está se preparando para ficar em conformidade com as normativas da LGPD:</p>
                <p class="mt-3">
                  <a class="mt-3 font-extrabold underline" href="assets/contracts/politica_interna_privacidade_protecao_dados_novarede.pdf" target="_blank">POLÍTICA INTERNA DE PRIVACIDADE E PROTEÇÃO DE DADOS DA NOVA REDE TELECOMUNICAÇÕES LTDA</a>
                </p>
                <p class="mt-3">
                  <a class="mt-3 font-extrabold underline" href="assets/contracts/termo_nomeacao_encarregado.pdf" target="_blank"> TERMO DE NOME DE ENCARREGADO (DATA PROTECTION OFFICER)</a>
                </p>
              </faq-accordion>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import FaqAccordion from "@/components/layout/goods/FaqAccordion.vue";
export default {
  name: "App",
  components: {
    FaqAccordion,
  },
};
</script>

FaqAccordion
